<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.sys.international.title.editHeadTitle') : $t('cip.plat.sys.international.title.viewHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script  src="https://cdn.bootcss.com/FileSaver.js/2014-11-29/FileSaver.js"></script>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
export default {
  name: "add",
  components: {
    FormLayout,
    HeadLayout,
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
          }
        );
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t("cip.plat.sys.international.field.content"),
          prop: 'content',
          type: "textarea",
          minRows: 4,
          maxRows: 34,
          span: 24,
        },
      ]
    }
  },
  created() {
    let {id, type, data} = this.$route.query;
    this.type = type;
    this.dataForm.content = data
  },
  mounted() {

  },
  methods: {
    // init(value, type, name) {
    //   //js 写文件
    //
    //
    //   var blob;
    //
    //   if (typeof window.Blob == "function") {
    //
    //     blob = new Blob([value], {
    //
    //       type: type
    //
    //     });
    //
    //   } else {
    //
    //     var BlobBuilder = window.BlobBuilder || window.MozBlobBuilder || window.WebKitBlobBuilder || window.MSBlobBuilder;
    //
    //     var bb = new BlobBuilder();
    //
    //     bb.append(value);
    //
    //     blob = bb.getBlob(type);
    //
    //   }
    //
    //   var URL = window.URL || window.webkitURL;
    //
    //   var bloburl = URL.createObjectURL(blob);
    //
    //   var anchor = document.createElement("a");
    //
    //   if ('download' in anchor) {
    //
    //     anchor.style.visibility = "hidden";
    //
    //     anchor.href = bloburl;
    //
    //     anchor.download = name;
    //
    //     document.body.appendChild(anchor);
    //
    //     var evt = document.createEvent("MouseEvents");
    //
    //     evt.initEvent("click", true, true);
    //
    //     anchor.dispatchEvent(evt);
    //
    //     document.body.removeChild(anchor);
    //
    //   } else if (navigator.msSaveBlob) {
    //
    //     navigator.msSaveBlob(blob, name);
    //
    //   } else {
    //
    //     location.href = bloburl;
    //
    //   }
    //
    //
    // },


    to() {
      // console.log(this.dataForm.content,"1111111")
      // let data=this.dataForm.content
      // foo(data)
      // var fso = new ActiveXObject("Scripting.FileSystemObject");
      // var fh = fso.OpenTextFile("E:\\cmd\\sinoma-web\\src\\views\\system\\international\\a.txt", 8);
      //
      // fh.WriteLine(row.content);
      // fh.Close();

//       let f_data = []
//
//       let table_data = document.getElementsByClassName('ant-table-tbody')[0]
//       let data_all = table_data.getElementsByTagName('tr')
//       for ( let i = 0; i <data_all.length; i++){
//         let data_item = data_all[i].getElementsByTagName('td')
//         let item = []
//         for(let j = 0; j < data_item.length; j++){
//           let item_value = data_item[j].textContent
//           console.log(item_value)
//           item.push(item_value)
//         }
//         f_data.push(item)
//       }
//       console.log(f_data)
//
// // 下载保存到csv格式
//       str = `时间,坝下水位,坝上水位,生态流量,入库流量,出库流量\n`
//       for(let i = 0; i<f_data.length; i++){
//         for(let j = 0; j < f_data[i].length; j++){
//           str += `${f_data[i][j] + '\t'},`
//         }
//         str += '\n'
//       }
//       const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
//       const link = document.createElement('a')
//       link.href = uri
//       link.download = "数据2022.csv"
//       link.click()
// in index.js
//       console.log(foo());
//       foo();
      // console.log(fs,'156')
      // var fso = new ActiveXObject("Scripting.FileSystemObject");
      // this.dataForm.content=JSON.parse(this.dataForm.content)




      // var blob = new Blob([this.dataForm.content], {type: "text/plain;charset=utf-8"});
      // saveAs(blob, "a.json");




      // var oWordApp = new ActiveXObject("Word.Application");
      //
      // var oDocument = oWordApp.Documents.Open("E:\\cmd\\sinoma-web\\src\\views\\system\\international\\a.txt");
      //
      // oDocument.SaveAs("E:\\cmd\\sinoma-web\\src\\views\\system\\international\\a.txt", 8);
      // fs.appendFile("E:\\cmd\\sinoma-web\\src\\lang\\a.txt")

      // let path = require('path');
      // let PUBLIC_PATH = path.resolve(__dirname, 'a.txt');
      // fs.readFile(PUBLIC_PATH, 'utf8', function (err, data) {
      //   if (err) console.log(err);
      //
      // });
      // var f1 = fso.createtextfile(PUBLIC_PATH,true);
    // fs.createWriteStream("a.txt","1111")
// 1. 导入fs文件系统模块
// 2. 调用fs.writeFile()方法，写入文件的内容
//      参数1：表示文件的存放路径
//      参数2：表示要写入的内容
//      参数3：回调函数
//       fs.writeFile('E:\\cmd\\sinoma-web\\src\\lang\\a.txt','Hello world',err => {
//         if(err) return console.log(err);
//         console.log('===============');
//         console.log('写入成功');
//       })

        // try {
        //    writeFile('E:\\cmd\\sinoma-web\\src\\lang\\a.txt','Hello world');
        //   console.log('Saved')
        // } catch (error) {
        //   console.log(error)
        // }


      // var caFile = fs.readFile('a.txt', 'utf8', function(err, data){
      //   if(err){
      //     console.log(err);
      //   }else{
      //     return data;
      //   }
      // });
      // console.log(caFile)


    },
    headSave(cancel = false) {
      this.to();
      // this.$refs.formLayout.$refs.form.validate(async (valid) => {
      //   if (valid) {
      //
      //   }
      // })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
